import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form, {
      inline: true,
      class: "selectdate"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: "选择日期" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.DateSelect,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.DateSelect) = $event)),
                  ref: "myRef",
                  onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.datePickerVisible = true)),
                  type: "daterange",
                  align: "right",
                  "value-format": "YYYY-MM-DD",
                  "unlink-panels": "",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  shortcuts: _ctx.shortcuts
                }, null, 8, ["modelValue", "shortcuts"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.selectTouSu,
              icon: "el-icon-search"
            }, {
              default: _withCtx(() => [
                _createTextVNode("查询")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.activeName,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activeName) = $event)),
      type: "card",
      onTabClick: _ctx.changeClick
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: "按费用统计",
          name: "fee"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table, {
              ref: "table",
              data: _ctx.complaintFormStatisticsList,
              border: "",
              style: {"width":"100%"},
              height: "70vh",
              "row-key": "PackagesName",
              stripe: "",
              "header-cell-style": {'text-align':'center'},
              "cell-style": {'text-align':'center'}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  prop: "PackagesName",
                  label: "套餐名称",
                  width: "500"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "TotalFee",
                  label: "总退费金额（元）",
                  width: "300"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "RefundType",
                  label: "退费类型",
                  width: "230"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "Operator",
                  label: "操作人",
                  width: "300"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "ExpectedFee",
                  label: "预计退费金额"
                })
              ]),
              _: 1
            }, 8, ["data"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "按数量统计",
          name: "num"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table, {
              ref: "table",
              data: _ctx.complaintFormStatisticsCountList,
              border: "",
              style: {"width":"100%"},
              height: "70vh",
              "row-key": "PackagesName",
              stripe: "",
              "header-cell-style": {'text-align':'center'},
              "cell-style": {'text-align':'center'}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  prop: "RefundType",
                  label: "退费类型",
                  width: "300"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "Operator",
                  label: "操作人",
                  width: "300"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "Count",
                  label: "次数",
                  width: "300"
                })
              ]),
              _: 1
            }, 8, ["data"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "onTabClick"])
  ]))
}